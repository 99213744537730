import React, { useState } from 'react';
import bann from '../img/carosl1.jpg';
import banner1 from '../img/slide1.jpg';
import banner2 from '../img/slide2.jpg';
import banner3 from '../img/slide3.jpg';
import banner4 from '../img/slide4.jpg';
import about from '../img/ABOUT.jpg';

const AppointmentForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        date: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Construct the WhatsApp URL with form data
        const whatsappNumber = "+919413100261"; // WhatsApp number to redirect to
        const whatsappMessage = `Hello, I would like to book an appointment.
        \n*Name:* ${formData.name}
        \n*Email:* ${formData.email}
        \n*Phone:* ${formData.phone}
        \n*Preferred Date:* ${formData.date}
        \n*Message:* ${formData.message}`;

        // Encode message to handle special characters
        const encodedMessage = encodeURIComponent(whatsappMessage);

        // Open WhatsApp link
        window.open(`https://wa.me/${whatsappNumber}?text=${encodedMessage}`, '_blank');
    };

    return (
        <div className="appointment-wrapper">
            <div className="appointment-form-container">
                <h2 className="appointment-header">Book an Appointment</h2>
                <form className="appointment-form" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="name">Full Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Enter your phone number"
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="date">Preferred Appointment Date</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="message">Additional Notes</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Enter any additional information"
                        ></textarea>
                    </div>

                    <button type="submit" className="submit-btn">Book Appointment</button>
                </form>
            </div>

            <div className="blog-image-container">
                <center><h1 className="appointment-header">Our Blogs</h1></center>
                <img src={bann} alt="Blog/Image" className="blog-image" />
                <img src={banner1} alt="Blog/Image" className="blog-image" />
                <img src={banner2} alt="Blog/Image" className="blog-image" />
                <img src={banner4} alt="Blog/Image" className="blog-image" />
                <img src={banner3} alt="Blog/Image" className="blog-image" />
                <img src={about} alt="Blog/Image" className="blog-image" />
            </div>
        </div>
    );
};

export default AppointmentForm;
