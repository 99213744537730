import React from 'react';
import { Carousel } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa'; // Using FontAwesome for star icons

const Review = () => {
  const reviews = [
    {
      name: "SK Singh",
      review: "We have been facilitated here good and we like the courtesy of the staff at OPCH hospital Lucknow. Also heartfully thanks to Dr. Dinesh who is very skilled in his field. Thank you a lot.",
      date: "3 days ago",
      rating: 5,
    },
    {
      name: "Deepak Kumar Singh",
      review: `"I am incredibly grateful to Dr. Dinesh for his exceptional skill, professionalism, and compassionate care during my pyeloplasty operation; his expertise, attention to detail, and reassuring approach turned a daunting experience into one of trust and confidence, and I couldn’t have been in better hands—highly recommended for anyone in need of dedicated and thorough care."`,
      date: "a month ago",
      rating: 5,
    },
    {
      name: "Adarsh Pandey",
      review: "Dr. Dinesh Rahar is a very professional, skilled, and nice personality in his field. Also, he is very polite and caring with his patients.",
      date: "a month ago",
      rating: 5,
    },
    {
      name: "Gurmeet Kaur",
      review: "Doctor is very good, My operation was done very well. At this time I am fit and good. By - Patient S.B.Pal",
      date: "a week ago",
      rating: 5,
    },
    {
      name: "Shubham Pathak",
      review: "Best urologist, doctor behavior is very polite, and he is extremely good in his field. Doctor skill is very good, he examined the patient honestly. Thank you 🙏",
      date: "3 months ago",
      rating: 5,
    },
    {
      name: "Prashant Dubey",
      review: "Dr. Dinesh Rahar is a young and dynamic urologist in Lucknow, using new techniques to treat his patients. Takes very good care of patients with an expert technical team.",
      date: "a year ago",
      rating: 5,
    },
    {
      name: "Umang Jain",
      review: "Dr. Dinesh Rahar is one of the finest urologists in India and a wonderful human being. He treats his patients in a very comforting manner with his in-depth knowledge and vast experience.",
      date: "2 years ago",
      rating: 5,
    },
    {
      name: "Amrit Verman",
      review: "Good facilities and centre of excellence for all kinds of Urology and Kidney care. Dr. Dinesh has long experience in Urology and he is an expert urologist especially for PCNL, RIRS laser stone surgery & Laparoscopic procedure.",
      date: "2 years ago",
      rating: 5,
    },
    {
      name: "Daksha Jain",
      review: "Dr. Dinesh has always explained the root cause of the disease and ensured that only the required treatment is given. He avoids giving unnecessary medication and tests. Keep up the good work, Doc!",
      date: "2 years ago",
      rating: 5,
    }
  ];

  // Function to render stars based on the rating
  const renderStars = (rating) => {
    let stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<FaStar key={i} className="text-warning" />);
    }
    return stars;
  };

  return (
    <div className="container mt-5">
      <h4 className="text-center mb-4">What Our Patients Say</h4>
      <Carousel interval={2000} indicators={false} controls={false}>
        {reviews.map((review, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-center">
              <div className="card p-4" style={{ maxWidth: '500px', width: '100%' }}>
                <h5>{review.name}</h5>
                <p className="text-muted">{review.date}</p>
                <div>{renderStars(review.rating)}</div>
                <p>{review.review}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Review;
