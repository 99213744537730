import React from 'react';
import Footer from '../component/Footer';
import oncologyImage from '../img/infertility.png'; // Make sure you have an image in the specified folder
import treatmentImage from '../img/normal.png'; // Another image related to the treatment

const Oncology = () => {
  return (
    <>
      <section style={{ padding: '2rem', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
        <h1 style={{ color: '#2d2d2d' }}>Urology Oncology</h1>
        <img 
          src={oncologyImage} 
          alt="Oncology care" 
          style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px', marginBottom: '2rem' }} 
        />
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Urology oncology is a specialized field focusing on the diagnosis and treatment of cancers
          affecting the urinary tract and male reproductive system, including bladder, kidney, prostate,
          and testicular cancers. Our multidisciplinary team is committed to providing compassionate care
          and the latest medical advancements.
        </p>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#fff', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Our Approach to Treatment</h2>
        <img 
          src={treatmentImage} 
          alt="Cancer treatment" 
          style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px', marginBottom: '2rem' }} 
        />
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          We offer a comprehensive range of services from early diagnosis to advanced surgical techniques 
          and cutting-edge treatments like robotic surgery, chemotherapy, immunotherapy, and targeted therapies.
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Bladder Cancer</li>
          <li>Prostate Cancer</li>
          <li>Kidney Cancer</li>
          <li>Testicular Cancer</li>
        </ul>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Why Choose Us?</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Our team of expert oncologists and urologists collaborate to create a personalized treatment plan
          tailored to your specific needs. We prioritize the latest technological advancements and holistic care,
          ensuring you receive the best possible outcomes.
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>State-of-the-art facilities</li>
          <li>Expert team of specialists</li>
          <li>Comprehensive support and rehabilitation</li>
          <li>Advanced diagnostic tools and minimally invasive surgery</li>
        </ul>
      </section>

      <Footer />
    </>
  );
};

export default Oncology;
