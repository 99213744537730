import React from 'react'

import Footer from './Footer'
import AppointmentForm from './AppointmentForm'

const Contact = () => {
  return (
    <div>
     <AppointmentForm/>
     <Footer/>
    </div>
  )
}

export default Contact
