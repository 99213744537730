import React from 'react'
import infert from '../img/infertility.png'
import lapro from '../img/lapro.png'
import unwant from '../img/unwanted.png'
import normal from '../img/normal.png'
import surgety from '../img/General Surgery.png'

import { Link } from 'react-router-dom'

const Department = () => {
    return (
        <>


            <div className='department'>

                <h1>Treatments</h1>


                <div className='depart'>


                    <div className='depart2'>
                        <img src={infert} />
                        <h2>Urologic Oncology </h2>
                    </div>

                    <div className='depart2'>
                        <img src={lapro} />
                        <h2>Pediatric Urology</h2>
                    </div>

                    <div className='depart2'>
                        <img src={unwant} />
                        <h2>Reconstructive Urology

                        </h2>
                    </div>

                    <div className='depart2'>
                        <img src={normal} />
                        <h2>Cystoscopy</h2>
                    </div>

                    <div className='depart2'>
                        <img src={surgety} />
                        <h2>Andrology</h2>
                    </div>


                </div>

            </div>



            <div className='department'>



                <br />
                <div className='depart'>


                    <div className='depart2'>
                        <img src={infert} />
                        <h2>Genitourinary Surgery </h2>
                    </div>

                    <div className='depart2'>
                        <img src={lapro} />
                        <h2>Prostate Laser Surgery</h2>
                    </div>

                    <div className='depart2'>
                        <img src={unwant} />
                        <h2>Ureteroscopy (URS)

                        </h2>
                    </div>

                    <div className='depart2'>
                        <img src={normal} />
                        <h2>Urinary Tract Infection (UTI)</h2>
                    </div>

                    <div className='depart2'>
                        <img src={surgety} />
                        <h2>Minimally Invasive Urology</h2>
                    </div>


                </div>

            </div>

        </>
    )
}

export default Department
