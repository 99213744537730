import React from 'react';
import Footer from '../component/Footer';
import andrologyImage from '../img/slide1.jpg'; // Make sure you have an image in the specified folder

const Andrology = () => {
  return (
    <>
      <section style={{ padding: '2rem', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
        <h1 style={{ color: '#2d2d2d' }}>Andrology</h1>
        <img 
          src={andrologyImage} 
          alt="Andrology care" 
          style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px', marginBottom: '2rem' }} 
        />
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Andrology is the medical specialty that focuses on male health, particularly related to the 
          male reproductive system and urological problems that are unique to men. Our team of specialists 
          is dedicated to addressing conditions such as male infertility, erectile dysfunction, and other 
          reproductive health issues.
        </p>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#fff', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Common Andrological Conditions</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          We provide treatment and diagnosis for a variety of male reproductive conditions, including:
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Erectile dysfunction (ED)</li>
          <li>Male infertility</li>
          <li>Peyronie’s disease</li>
          <li>Hypogonadism (low testosterone)</li>
          <li>Prostate conditions</li>
        </ul>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Our Expertise in Andrology</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Our experienced andrology specialists work with cutting-edge technology and offer personalized 
          treatment plans to ensure the best possible outcomes for our patients. We prioritize patient 
          privacy and comprehensive care for the treatment of reproductive health issues.
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Advanced diagnostic tools</li>
          <li>Minimally invasive procedures</li>
          <li>Collaborative approach with urologists and endocrinologists</li>
          <li>Focus on male infertility and sexual health</li>
        </ul>
      </section>

      <Footer />
    </>
  );
};

export default Andrology;
