import React from 'react'

const Header = () => {
  return (
    <div className='head'>
      <p > <i class="fa fa-phone"></i> +91 9413100261</p>
      <p> <i class="fa fa-envelope"></i> dineshrahar@gmail.com</p>

    </div>
  )
}

export default Header

