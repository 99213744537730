import React from 'react';
import Footer from '../component/Footer';
import prostateLaserImage from '../img/slide3.jpg'; // Make sure you have the image in the specified folder

const Prostate = () => {
  return (
    <>
      <section style={{ padding: '2rem', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
        <h1 style={{ color: '#2d2d2d' }}>Prostate Laser Surgery</h1>
        <img 
          src={prostateLaserImage} 
          alt="Prostate Laser Surgery" 
          style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px', marginBottom: '2rem' }} 
        />
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Prostate laser surgery is a minimally invasive procedure used to treat benign prostatic hyperplasia (BPH),
          an enlarged prostate. This advanced surgical method uses laser technology to remove prostate tissue 
          that is blocking urine flow, helping men relieve symptoms such as difficulty urinating, frequent urination,
          or inability to completely empty the bladder.
        </p>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#fff', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Benefits of Prostate Laser Surgery</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Prostate laser surgery offers many advantages over traditional surgical methods, including:
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Less bleeding and shorter recovery time</li>
          <li>Lower risk of complications</li>
          <li>Faster symptom relief</li>
          <li>Minimal hospital stay</li>
          <li>Improved urinary function</li>
        </ul>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Who is a Candidate for Prostate Laser Surgery?</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Patients experiencing moderate to severe symptoms of BPH, or those who have not responded well to 
          medications, may be ideal candidates for prostate laser surgery. Our experienced urology team will 
          assess your condition and recommend the best treatment option based on your individual needs.
        </p>
      </section>

      <Footer />
    </>
  );
};

export default Prostate;
