import React, { useEffect, useState } from 'react';
import banner1 from '../img/slide1.jpg'
import banner2 from '../img/slide2.jpg'
import banner3 from '../img/slide3.jpg'
import banner4 from '../img/slide4.jpg'

import about from '../img/ABOUT.jpg'

import { Link } from 'react-router-dom'

const Home = () => {
    const [index, setIndex] = useState(0);
    const slides = document.getElementsByClassName("mySlides");

    useEffect(() => {
        const handleCarousel = () => {
            if (slides.length > 0) {
                for (let i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                setIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % slides.length;
                    slides[newIndex].style.display = "block";
                    return newIndex;
                });
            }
        };

        handleCarousel();
        const intervalId = setInterval(handleCarousel, 3000); // Change image every 2 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [slides.length]);

    return (
        <>

            <div className='header'>

                <div className='header1'>
                    <h1> Dr. Dinesh Rahar   Best Urologist  Lucknow</h1>
                    
                    <h2>Empower Your Health, Empower Your Life</h2>

                    <div style={{ display: 'flex' }}>
                        <Link to='/Contact'><button>Contact Us </button></Link>
                        <a href='https://maps.app.goo.gl/DdNYrZWbV7tgAhoB8'><button>Get Direction  </button></a>
                    </div>
                </div>

                <div className='header2'>

                    <div className="mySlides">
                        <img src={banner1} />
                    </div>

                    <div className="mySlides">
                        <img src={banner2} />
                    </div>

                    <div className="mySlides">
                        <img src={banner3} />
                    </div>

                    <div className="mySlides">
                        <img src={banner4} />
                    </div>
                </div>

            </div>



            <div className='about'>


                <div className='about2'>
                    <img src={about} />
                </div>

                <div className='about2'>
                    <h1>Welcome to Dinesh Rahar</h1>

                    <h2>Senior Urologist & Renal Transplant Specialist</h2>
                    <h4>OPCH Hospital
                        Ajanta Hospital, Lucknow, UP </h4>

                    <h3>Education & Professional Training</h3>

                    <p>
                        <i class="fa fa-circle"></i> Senior Urologist at <b>Ram Manohar Lohia Institute</b> of Medical Sciences (2022-2024)</p>


                    <p> <i class="fa fa-circle"></i> M.Ch. (Urology & Renal Transplantation)
                        Govt. <b>Kilpauk Medical College & Hospital, Chennai</b> (2019–2022)</p>

                    <p> <i class="fa fa-circle"></i> M.S. (General Surgery)
                        <b>SMS Medical College, Jaipur, Rajasthan</b> (2015–2018)</p>

                    <p> <i class="fa fa-circle"></i> M.B.B.S.
                        <b>Govt. Medical College, Kota, Rajasthan</b> (2008–2013)</p>

                    <Link to='/About' className='btn btn-primary mt-6 ms-5'>Read More</Link>

                </div>


            </div>





        </>
    )
}

export default Home
