import React from 'react';
import Slider from 'react-slick'; // Importing react-slick slider
import banner1 from '../img/carosl1.jpg';

import banner3 from '../img/carosl3.jpg';
import '../component/caro.css'; // Custom styles

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const Carousal = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, 
    fade: true, // Enable fade animation
    arrows: true,
    adaptiveHeight: true, // Adapt height to the image
  };

  return (
    <div className="carousal-container">
      <Slider {...settings}>
        <div>
          <img src={banner1} alt="Banner 1" className="carousel-image" />
        </div>
        {/* <div>
          <img src={banner2} alt="Banner 2" className="carousel-image" />
        </div> */}
        <div>
          <img src={banner3} alt="Banner 3" className="carousel-image" />
        </div>
      </Slider>
    </div>
  );
}

export default Carousal;
