import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './component/Layout';
import Navbar from './component/Navbar';

// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';


import './App.css';
import Header from './component/Header';
import Contact from './component/Contact';
import About from './component/About';
import Gallery from './component/Gallery';
import Oncology from './pages/Oncology';
import Uropedia from './pages/Uropedia';
import Andrology from './pages/Andrology';
import Genitourinary from './pages/Genitourinary';
import Prostate from './pages/Prostate';
import Form from './component/Form';

function App() {
  return (
    <div className='main'>


      <BrowserRouter >
        <Header />
        <Navbar />
        <Routes>
          <Route path="*" element={<Layout />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Oncology" element={<Oncology />} />
          <Route path="/Uropedia" element={<Uropedia />} />
          <Route path="/Andrology" element={<Andrology />} />
          <Route path="/Genitourinary" element={<Genitourinary />} />
          <Route path="/Prostate" element={<Prostate />} />
          <Route path="/Form" element={<Form />} />

        </Routes>
      </BrowserRouter>


    </div>
  );
}

export default App;
