import React from 'react'
import { FaWhatsapp, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/+919413100261", "_blank"); // Your WhatsApp number
  };

  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/Urologist.Transplantsurgeon.Andrologist?mibextid=LQQJ4d", "_blank"); // Your Facebook profile
  };

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/rahar20?igsh=MWtvd2ExeDV5aWc1OA%3D%3D&utm_source=qr", "_blank"); // Your Instagram profile
  };

  const handleYouTubeClick = () => {
    window.open("https://www.youtube.com/yourchannel", "_blank"); // Your YouTube channel
  };
  return (
    <>
      <div className='footer' style={{ paddingBottom: '30px' }}>

        <div className='foot'>
          <h1>About</h1>
          <p>Academic Urologist with vast experience of over 11 years, in the field of Urology, Endo Urology, PCNL, Laser Lithotripsy, Uro Oncology, Laparoscopy, Renal Transplantation, Robotic Urology & Andrology.</p>
        </div>

        <div className='foot'>
          <h1>Services</h1>
          
          <div className='tabs'>
          <Link to='/Oncology' style={{ textDecoration: 'none' }}>  <p>Urologic Oncology</p></Link>
          <Link to='/Uropedia' style={{ textDecoration: 'none' }}><p>Pediatric Urology</p></Link>
          <Link to='/Genitourinary' style={{ textDecoration: 'none' }}><p>Urology Surgery</p></Link>
          <Link to='/Andrology' style={{ textDecoration: 'none' }}><p>Andrology</p></Link>

          <Link to='/Genitourinary' style={{ textDecoration: 'none' }}><p>  Genitourinary Surgery     </p></Link>

          <Link to='/Prostate' style={{ textDecoration: 'none' }}><p>   Prostate Laser Surgery    </p></Link>

          </div>

        </div>

        <div className='foot'>
          <h1>Quick Link</h1>
          <div className='tabs'>
          <Link to='/' style={{ textDecoration: 'none' }}><p>Home </p></Link>
          <Link to='/About' style={{ textDecoration: 'none' }}><p>About Us </p></Link>
          <Link to='/Contact' style={{ textDecoration: 'none' }}><p>Appointment</p></Link>
          <Link to='/Gallery' style={{ textDecoration: 'none' }}><p>Gallery</p></Link>
          </div>
        </div>

        <div className='foot'>
          <h1>Contact Us</h1>
          {/* <p><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14249.430605614702!2d80.9470908!3d26.7648671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb75e9bdd8b5%3A0x67762644ca5fe657!2sDr.%20O.P.%20Chaudhary%20Hospital%20and%20Research%20Center!5e0!3m2!1sen!2sin!4v1726745019056!5m2!1sen!2sin" ></iframe></p> */}
          <p style={{ fontSize: '15px' }}> <a href="https://maps.app.goo.gl/uhdPEGQmhUKLDuuC8" style={{color:'white', textDecoration:'none'}}>Address 1 : Dr O P, <b>Chaudhary Hospital and Research Centre</b> Chaudhary vihar, Raibareli Rd, Sector 1, Vrindavan Colony, Lucknow, Uttar Pradesh 226029</a></p>
          <p style={{ fontSize: '15px' }}>Timing : 10 PM TO 2 PM (Sunday Closed)</p>
          <p style={{ fontSize: '15px' }}><a href="https://maps.app.goo.gl/L5mww7SqyzWojKv57" style={{color:'white', textDecoration:'none'}}>Address 2 :  <b>Ajanta Hospital</b> . ABC Complex 765 Kanpur Lucknow Rd Sardari Khera Sujanpura Alambag Lucknow 226005</a>   </p>
          <p style={{ fontSize: '15px' }}>Timing : 2 PM TO 6 PM (Sunday Closed)</p>

        </div>



      </div>

      <div className='discreption'>

        <p>@2024 Dr. Dinesh Rahar  Developed Designed by Creative Digital World </p>
      </div>


      <div className="social-buttons-container">
        <button className="social-button whatsapp" onClick={handleWhatsAppClick}>
          <FaWhatsapp size={40} color="white" />
        </button>
        <button className="social-button facebook" onClick={handleFacebookClick}>
          <FaFacebook size={40} color="white" />
        </button>
        <button className="social-button instagram" onClick={handleInstagramClick}>
          <FaInstagram size={40} color="white" />
        </button>
        <button className="social-button youtube" onClick={handleYouTubeClick}>
          <FaYoutube size={40} color="white" />
        </button>
      </div>


    </>
  )
}

export default Footer
