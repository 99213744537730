import React from 'react'
import Home from './Home'
import Department from './Department'
import Carousal from './Carousal'
import Footer from './Footer'
import AppointmentForm from './AppointmentForm'
import Review from '../pages/Review'


const Layout = () => {
  return (
    <div className='main'>
      
      <Carousal />

      <br/><br/>
      <Home />
  
      <Department />
      <AppointmentForm />
      <Review/>
      <Footer />


    </div>
  )
}

export default Layout
