import React from 'react';
import Footer from '../component/Footer';
import genitourinaryImage from '../img/slide2.jpg'; // Ensure you have the image in the specified folder

const Genitourinary = () => {
  return (
    <>
      <section style={{ padding: '2rem', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
        <h1 style={{ color: '#2d2d2d' }}>Genitourinary Surgery</h1>
        <img 
          src={genitourinaryImage} 
          alt="Genitourinary Surgery" 
          style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px', marginBottom: '2rem' }} 
        />
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Genitourinary surgery focuses on diseases and disorders of the urinary and genital tracts. Our team of 
          skilled surgeons provides both minimally invasive and traditional surgical treatments for a variety of 
          conditions affecting the kidneys, bladder, urethra, and reproductive organs.
        </p>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#fff', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Common Genitourinary Conditions Treated</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Our team treats a wide range of genitourinary conditions, including:
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Kidney stones</li>
          <li>Bladder cancer</li>
          <li>Kidney cancer</li>
          <li>Prostate conditions</li>
          <li>Urinary incontinence</li>
          <li>Pelvic organ prolapse</li>
        </ul>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Why Choose Us for Genitourinary Surgery?</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Our genitourinary surgery center is staffed with experienced surgeons who use advanced surgical techniques, 
          including robotic and minimally invasive surgery, to ensure the best outcomes for our patients. We work closely 
          with a team of urologists, oncologists, and nephrologists to offer a comprehensive approach to treatment.
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Minimally invasive and robotic surgeries</li>
          <li>Highly experienced surgical team</li>
          <li>Comprehensive pre-operative and post-operative care</li>
          <li>Collaborative approach with urology and oncology specialists</li>
        </ul>
      </section>

      <Footer />
    </>
  );
};

export default Genitourinary;
