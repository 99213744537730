import React from 'react';
import Footer from '../component/Footer';
import pediatricUrologyImage from '../img/slide1.jpg'; // Make sure you have an image in this path

const Uropedia = () => {
  return (
    <>
      <section style={{ padding: '2rem', textAlign: 'center', backgroundColor: '#f5f5f5' }}>
        <h1 style={{ color: '#2d2d2d' }}>Pediatric Urology</h1>
        <img 
          src={pediatricUrologyImage} 
          alt="Pediatric Urology care" 
          style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px', marginBottom: '2rem' }} 
        />
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Pediatric urology focuses on the diagnosis and treatment of urological conditions affecting 
          infants, children, and adolescents. Our specialists provide compassionate, family-centered care 
          using the latest technology to ensure the best outcomes for our young patients.
        </p>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#fff', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Common Pediatric Urological Conditions</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          We treat a wide range of urological conditions in children, including:
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Urinary tract infections (UTIs)</li>
          <li>Bedwetting (nocturnal enuresis)</li>
          <li>Hydronephrosis (swelling of the kidney)</li>
          <li>Undescended testicles</li>
          <li>Hypospadias (abnormal urethra opening)</li>
        </ul>
      </section>

      <section style={{ padding: '2rem', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
        <h2 style={{ color: '#2d2d2d' }}>Why Choose Our Pediatric Urology Center?</h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#555', margin: '0 2rem' }}>
          Our pediatric urology team is dedicated to providing the highest quality care to children. We focus 
          on minimally invasive procedures and personalized treatment plans, ensuring that each child receives 
          tailored care in a supportive environment.
        </p>
        <ul style={{ fontSize: '1.2rem', color: '#555', textAlign: 'left', margin: '2rem auto', maxWidth: '800px' }}>
          <li>Minimally invasive surgery</li>
          <li>Expert pediatric urologists</li>
          <li>Comprehensive follow-up care</li>
          <li>State-of-the-art diagnostic tools</li>
          <li>Family-centered care approach</li>
        </ul>
      </section>

      <Footer />
    </>
  );
};

export default Uropedia;
