import React from 'react';
import Footer from './Footer';

import gal1 from '../img/gal1.jpg';
import gal2 from '../img/gal2.jpg';
import gal3 from '../img/gal3.jpg';
import gal4 from '../img/gal4.jpg';
import gal5 from '../img/gal5.jpg';

const Gallery = () => {
  const images = [gal1, gal2, gal3, gal4, gal5];

  return (

    <>
    <div className="container my-5">
      <center>
        <h1 className="mb-4">Gallery</h1>
      </center>
      <div className="row g-3">
        {images.map((image, index) => (
          <div key={index} className="col-md-4 col-sm-6">
            <div className="card shadow-sm">
              <img src={image} className="card-img-top img-fluid" alt={`Gallery ${index + 1}`} />
            </div>
          </div>
        ))}
      </div>
     
    </div>
     <Footer />

    </>
  );
};

export default Gallery;
