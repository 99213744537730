import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png'

function BasicExample() {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <Navbar expand="lg" expanded={expanded} className="navigation">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={handleNavItemClick}>
                
                <img src={logo}/>
                
                </Navbar.Brand>



                <Navbar.Toggle
                    aria-controls="navbarSupportedContent"
                    onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="me-auto">
                        {/* <Nav.Link as={Link} to="/" className="color" onClick={handleNavItemClick}>Home</Nav.Link> */}

                        <Nav.Link as={Link} to="/About" className="color" onClick={handleNavItemClick}>About</Nav.Link>

                        <Nav.Link as={Link} to="/Uropedia" className="color" onClick={handleNavItemClick}>Pediatric Urology</Nav.Link>
                        
                      

                        <Nav.Link as={Link} to="/Oncology" className="color" onClick={handleNavItemClick}>Urologic Oncology</Nav.Link>
                        <Nav.Link as={Link} to="/Andrology" className="color" onClick={handleNavItemClick}>Andrology</Nav.Link>

                        <NavDropdown title="Surgeries" id="basic-nav-dropdown" className="custom-dropdown-title">
                            <NavDropdown.Item as={Link} to="/Genitourinary" onClick={handleNavItemClick}>Genitourinary Surgery</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Prostate" onClick={handleNavItemClick}>Prostate Laser Surgery</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Other Services" id="basic-nav-dropdown" className="custom-dropdown-title">
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Transurethral Resection of The Prostate (TURP)</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Ureteroscopy (URS)</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Urinary Tract Infection (UTI)</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Cystoscopy</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Minimally Invasive Urology</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Laparoscopy</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Urinary Incontinence (UI) Treatment</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Dysmenorrhea Treatment</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" onClick={handleNavItemClick}>Reconstructive Urology</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link as={Link} to="/Gallery" className="color" onClick={handleNavItemClick}>Gallery</Nav.Link>

                        <Nav.Link as={Link} to="/Contact" className="color" onClick={handleNavItemClick}>Appointment</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default BasicExample;
