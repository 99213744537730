import React from 'react'
import about from '../img/ABOUT.jpg'
import Footer from './Footer'

const About = () => {
    return (

        <>
            <div className='about'>

                <div className='about2'>
                    <img src={about} />
                   



                </div>

                <div className='about2'>


                    <section class="why-choose container py-5">
                        <h4 class="text-center mb-4">Why Choose Dr. Dinesh Rahar?</h4>
                        <ul class="list-group list-group-flush text-start mx-auto" >
                            <li class="list-group-item">
                                <strong>Extensive Surgical Experience</strong>: Performed numerous elective and emergency urological surgeries.
                            </li>
                            <li class="list-group-item">
                                <strong>Cutting-Edge Technology</strong>: Expertise in robotic and laparoscopic procedures.
                            </li>
                            <li class="list-group-item">
                                <strong>Patient-Centric Care</strong>: Dedicated to providing personalized and evidence-based treatment.
                            </li>
                        </ul>
                    </section>

                    <section class="training text-center container py-5">
                        <h2 class="mb-4">Advanced Training</h2>
                        <p class="fw-bold">Muljibhai Patel Urological Hospital, Nadiad, Gujarat</p>
                        <ul class="list-group list-group-flush d-inline-block text-start">
                            <li class="list-group-item">Robotic Prostatectomy</li>
                            <li class="list-group-item">Laparoscopic Donor Nephrectomy</li>
                            <li class="list-group-item">Partial Nephrectomy</li>
                            <li class="list-group-item">RIRS and Vascular Access Surgeries (RCF, BCF, AV Fistulas)</li>
                        </ul>

                        <p class="mt-4">
                            <strong>Johnson & Johnson, Chennai</strong><br />
                            Specialized Laparoscopic Urology Training
                        </p>
                    </section>

                </div>


            </div>



            <section className="clinical-expertise container py-5">
                <h2 className="text-center mb-4">Clinical Expertise</h2>
                <div className="row">


                    <div class="col-md-6 mb-4">
                        <h3>1. Laparoscopic Urology</h3>
                        <ul class="list-group">
                            <li class="list-group-item">Laparoscopic Donor Nephrectomy</li>
                            <li class="list-group-item">Laparoscopic Pyeloplasty</li>
                            <li class="list-group-item">Laparoscopic Adrenalectomy</li>
                            <li class="list-group-item">Laparoscopic Orchidopexy</li>
                            <li class="list-group-item">Laparoscopic Partial Nephrectomy</li>
                        </ul>
                    </div>


                    <div class="col-md-6 mb-4">
                        <h3>2. Robotic Urology</h3>
                        <ul class="list-group">
                            <li class="list-group-item">Robotic Prostatectomy (for Prostate Cancer)</li>
                            <li class="list-group-item">Robotic Partial Nephrectomy</li>
                            <li class="list-group-item">Robotic Cystectomy with Ileal Conduit or Neobladder</li>
                            <li class="list-group-item">Robotic Ureteral Reimplantation</li>
                        </ul>
                    </div>


                    <div class="col-md-6 mb-4">
                        <h3>3. Laser Urology</h3>
                        <ul class="list-group">
                            <li class="list-group-item">Holmium Laser Enucleation of the Prostate (HoLEP)</li>
                            <li class="list-group-item">Laser Lithotripsy (for Kidney and Ureteric Stones)</li>
                            <li class="list-group-item">Laser TURBT (for Bladder Tumor Removal)</li>
                        </ul>
                    </div>


                    <div class="col-md-6 mb-4">
                        <h3>4. Endourology Procedures</h3>
                        <ul class="list-group">
                            <li class="list-group-item">RIRS (Retrograde Intrarenal Surgery)</li>
                            <li class="list-group-item">PCNL (Percutaneous Nephrolithotomy)</li>
                            <li class="list-group-item">URSL (Ureteroscopy for Stone Removal)</li>
                            <li class="list-group-item">TURP (Transurethral Resection of the Prostate)</li>
                        </ul>
                    </div>


                    <div class="col-md-6 mb-4">
                        <h3>5. Renal Transplantation</h3>
                        <ul class="list-group">
                            <li class="list-group-item">Kidney Transplants</li>
                            <li class="list-group-item">AV Fistula Creation (for Dialysis Access)</li>
                        </ul>
                    </div>


                    <div class="col-md-6 mb-4">
                        <h3>6. Reconstructive Urology</h3>
                        <ul class="list-group">
                            <li class="list-group-item">Urethroplasty (BMG, Anastomotic)</li>
                            <li class="list-group-item">BOARI Flap Ureteral Reimplantation</li>
                            <li class="list-group-item">Penile Reconstruction</li>
                            <li class="list-group-item">Vesicovaginal Fistula Repair</li>
                            <li class="list-group-item">Bladder Augmentation</li>
                        </ul>
                    </div>


                    <div class="col-md-6 mb-4">
                        <h3>7. Oncological Urology</h3>
                        <ul class="list-group">
                            <li class="list-group-item">Radical Cystoprostatectomy (with Neobladder or Ileal Conduit Reconstruction)</li>
                            <li class="list-group-item">Radical Prostatectomy</li>
                            <li class="list-group-item">Radical Nephrectomy</li>
                            <li class="list-group-item">Radical Orchiectomy (for Testicular Cancer)</li>
                        </ul>
                    </div>


                    <div class="col-md-6 mb-4">
                        <h3>8. Specialized Surgeries</h3>
                        <ul class="list-group">
                            <li class="list-group-item">Penile Prosthesis Implantation</li>
                            <li class="list-group-item">Artificial Urinary Sphincter Placement</li>
                            <li class="list-group-item">Treatment of Complex Vesico-Ureteric Reflux</li>
                            <li class="list-group-item">Bladder and Ureteral Diverticulum Repair</li>
                        </ul>
                    </div>

                </div>
            </section>


            <Footer />

        </>
    )
}

export default About

